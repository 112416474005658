import SEO from "components/common/Seo"
import Layout from "components/layouts/Layout"
import { graphql } from "gatsby"
import React from "react"
import BlocksLayout from "components/layouts/BlocksLayout"

const Newsletter = ({ data, pageContext }) => {
  const {
    page: {
      pageBuilder: { blocks },
    },
  } = data

  return (
    <Layout background="lightestGrey" page={pageContext?.page}>
      <SEO {...pageContext.seo} />
      {blocks && <BlocksLayout blocks={blocks} />}
    </Layout>
  )
}

export const query = graphql`
  query newsletter($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...PageTemplateFragment
    }
  }
`

export default Newsletter
